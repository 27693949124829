<template>
  <div class="file-upload">
    <div class="file-upload-wrap">
      <!-- 只读，且文件为空时，占位 start -->
      <div v-if="disabled && !fileList.length">--</div>
      <!-- 只读，且文件为空时，占位 end -->

      <!-- 文件上传 start -->
      <el-upload
        v-else
        list-type="picture-card"
        :class="{
          'hide-upload-btn': fileList.length >= limit || disabled,
          'video-list-wrap': videoList.length,
        }"
        v-loading="uploadLoading"
        :multiple="multiple"
        :http-request="onUploadFile"
        :limit="limit"
        :file-list="fileList"
        :accept="acceptStr"
        :disabled="disabled"
        :before-upload="onBeforeUpload"
        :on-exceed="onExceed"
        :on-change="onChange"
        action
        drag
      >
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{ file }">
          <!-- 图片 start -->
          <div v-if="imageType.includes(getFileType(file))">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="onHandlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="onHandleRemoveFile(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
          <!-- 图片 end -->

          <!-- 其他文件 start -->
          <div v-else></div>
          <!-- 其他文件 end -->
        </div>
      </el-upload>
      <!-- 文件上传 end -->

      <!-- 视频文件 start -->
      <section v-if="videoList.length" class="video-wrap">
        <div
          v-for="(file, index) of videoList"
          :key="index"
          class="video-child"
        >
          <video ref="video" controlsList="nodownload" @contextmenu.prevent>
            <source :src="file.url" />
            您的浏览器不支持flash动画
          </video>
          <div class="handle">
            <span
              class="handle-preview"
              @click="onHandlePictureCardPreview(file)"
            >
              <i class="el-icon el-icon-zoom-in"></i>
            </span>
            <span
              v-if="!disabled"
              class="handle-delete"
              @click="onHandleRemoveFile(file)"
            >
              <i class="el-icon el-icon-delete"></i>
            </span>
          </div>
        </div>
      </section>
      <!-- 视频文件 end -->
    </div>

    <!-- 文件预览 start -->
    <c-preview-file
      v-model="dialogVisible"
      :fileList="fileList"
      :fileIndex="previewIndex"
      :fileType="fileType"
    ></c-preview-file>
    <!-- 文件预览 end -->
  </div>
</template>
<script>
import CPreviewFile from "@/components/common/CPreviewFile/index.vue";

export default {
  name: "BFileUpload",
  model: {
    prop: "fileList",
    event: "change",
  },
  components: {
    CPreviewFile,
  },
  props: {
    /** 文件列 */
    fileList: {
      type: Array,
      default: () => [],
    },
    /** 是否禁用 */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** 是否支持多选文件 */
    multiple: {
      type: Boolean,
      default: true,
    },
    /** 最大文件数量 */
    limit: {
      type: Number,
      default: undefined,
    },
    /** 最大上传限制，默认 50M */
    maxSize: {
      type: Number,
      default: 50 * 1024 * 1024,
    },
    /** 接收文件类型 */
    accepts: {
      type: Array,
      default: () => {
        return [
          "txt",
          "doc",
          "docx",
          "ppt",
          "pptx",
          "pdf",
          "jpg",
          "jpeg",
          "png",
          "mp4",
          "mp3",
          "wav",
          "xls",
          "xlsx",
          "zip",
          "rar",
        ];
      },
    },
  },
  data() {
    return {
      uploadLoading: false, // 上传加载Loading
      dialogVisible: false, // 图片预览是否开启
      previewIndex: 0, // 下标
      imageType: ["jpg", "jpeg", "png"], // 图片格式枚举
      videoType: ["mp4", "avi", "flv", "mov"], // 视频格式枚举
      fileType: null, // 当前文件后缀
    };
  },
  computed: {
    /**
     * 将后缀名数组拼接成字符串
     */
    acceptStr() {
      return this.accepts.map((ext) => "." + ext).join(",");
    },
    /**
     * 获取文件类型
     */
    getFileType() {
      return (file) => {
        const extIndex = file.name.lastIndexOf(".");
        const fileType = file.name.slice(extIndex + 1).toLocaleLowerCase();
        return fileType;
      };
    },
    /**
     * 视频列表
     */
    videoList() {
      const res = this.fileList.filter((child) => {
        const fileType = this.getFileType(child);
        return this.videoType.includes(fileType);
      });
      return res;
    },
  },
  methods: {
    /**
     * 上传文件前处理
     */
    onBeforeUpload(file) {
      // 是否超出最大文件大小限制
      const overLimit = file.size > this.maxSize;
      let matchType = false; // 是否符合格式
      if (!this.accepts || this.accepts.length === 0) {
        matchType = true;
      }
      this.fileType = this.getFileType(file);
      matchType = this.accepts.some((acc) => acc === this.fileType);
      if (overLimit || !matchType) {
        if (overLimit) {
          this.$message.warning(
            `文件超出${this.maxSize / 1024 / 1024}M，请压缩后上传!`
          );
        } else {
          this.$message.warning("文件类型不符合要求！");
        }
        this.onHandleRemoveFile(file);
      }
      return !overLimit && matchType;
    },
    /**
     * 超出数量处理
     */
    onExceed() {
      this.$message.warning(`抱歉，最多只能上传${this.limit}份文件`);
    },
    /**
     * 监听文件添加、移除、上传成功状态变化
     */
    onChange() {
      this.$emit("change", this.fileList);
    },
    /**
     * 上传操作
     */
    onUploadFile({ file }) {
      this.uploadLoading = true;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.ajaxPostUpload(file, e.target.result);
      };
    },
    /**
     * 上传接口
     */
    async ajaxPostUpload(file, result) {
      file.status = "ready";
      const params = {
        file_data: result,
      };
      try {
        const { data } = await this.$api.general.uploadImage(params);
        file.url = data;
        file.status = "success";
        const exist = this.fileList.find((item) => item.uid === file.uid);
        if (!exist) {
          this.fileList.push(file);
        }
      } catch (err) {
        file.status = "fail";
      } finally {
        this.uploadLoading = false;
      }
      console.log("🆒 ajaxPostUpload", file, this.fileList);
    },
    /**
     * 移除文件
     */
    onHandleRemoveFile(file) {
      this.$nextTick(() => {
        let existIndex = this.fileList.findIndex(
          (item) => item.uid === file.uid
        );
        this.fileList.splice(existIndex, 1);
      });
      this.$emit("change", this.fileList);
    },
    /**
     * 预览文件
     */
    onHandlePictureCardPreview(file) {
      const existIndex = this.fileList.findIndex(
        (item) => item.uid === file.uid
      );
      // console.log("🆒 onHandlePictureCardPreview", file, existIndex);
      this.previewIndex = existIndex;
      this.dialogVisible = true;
      this.fileType = this.getFileType(file);
    },
  },
};
</script>
<style lang="scss" scoped>
.file-upload {
  &-wrap {
    position: relative;
    display: inline-block;
    /deep/ .el-upload {
      &-list__item {
        margin: 0;
        margin-right: 10px;
      }
      &-dragger {
        width: 100%;
        height: 100%;
        border: none;
      }
    }
    .hide-upload-btn {
      /deep/ .el-upload {
        display: none;
      }
      &.video-list-wrap {
        /deep/ .el-upload {
          &-list {
            display: none;
          }
        }
      }
    }
  }
  /** 视频样式 */
  .video-wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    // gap: 15px;
    .video-child {
      width: 150px;
      height: 100px;
      border: 1px solid #dcdfe6;
      border-radius: 5px;
      box-sizing: border-box;
      video {
        width: 100%;
        height: 100%;
      }
    }
    .handle {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      width: 100%;
      height: 100%;
      background-color: rgba(#000000, 0.5);
      border-radius: 5px;
      .el-icon {
        font-size: 12px;
        color: #fff;
        cursor: pointer;
      }
      &-preview,
      &-delete {
        position: relative;
        top: 30%;
        left: 35%;
      }
      &-delete {
        left: 45%;
      }
    }
    &:hover {
      .handle {
        display: block;
      }
    }
  }
}
</style>

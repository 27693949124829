var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "file-upload" },
    [
      _c(
        "div",
        { staticClass: "file-upload-wrap" },
        [
          _vm.disabled && !_vm.fileList.length
            ? _c("div", [_vm._v("--")])
            : _c(
                "el-upload",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.uploadLoading,
                      expression: "uploadLoading",
                    },
                  ],
                  class: {
                    "hide-upload-btn":
                      _vm.fileList.length >= _vm.limit || _vm.disabled,
                    "video-list-wrap": _vm.videoList.length,
                  },
                  attrs: {
                    "list-type": "picture-card",
                    multiple: _vm.multiple,
                    "http-request": _vm.onUploadFile,
                    limit: _vm.limit,
                    "file-list": _vm.fileList,
                    accept: _vm.acceptStr,
                    disabled: _vm.disabled,
                    "before-upload": _vm.onBeforeUpload,
                    "on-exceed": _vm.onExceed,
                    "on-change": _vm.onChange,
                    action: "",
                    drag: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "file",
                      fn: function ({ file }) {
                        return _c("div", {}, [
                          _vm.imageType.includes(_vm.getFileType(file))
                            ? _c("div", [
                                _c("img", {
                                  staticClass: "el-upload-list__item-thumbnail",
                                  attrs: { src: file.url, alt: "" },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "el-upload-list__item-actions",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "el-upload-list__item-preview",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onHandlePictureCardPreview(
                                              file
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-zoom-in",
                                        }),
                                      ]
                                    ),
                                    !_vm.disabled
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "el-upload-list__item-delete",
                                            on: {
                                              click: function ($event) {
                                                return _vm.onHandleRemoveFile(
                                                  file
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-delete",
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ])
                            : _c("div"),
                        ])
                      },
                    },
                  ]),
                },
                [
                  _c("i", {
                    staticClass: "el-icon-plus",
                    attrs: { slot: "default" },
                    slot: "default",
                  }),
                ]
              ),
          _vm.videoList.length
            ? _c(
                "section",
                { staticClass: "video-wrap" },
                _vm._l(_vm.videoList, function (file, index) {
                  return _c("div", { key: index, staticClass: "video-child" }, [
                    _c(
                      "video",
                      {
                        ref: "video",
                        refInFor: true,
                        attrs: { controlsList: "nodownload" },
                        on: {
                          contextmenu: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c("source", { attrs: { src: file.url } }),
                        _vm._v(" 您的浏览器不支持flash动画 "),
                      ]
                    ),
                    _c("div", { staticClass: "handle" }, [
                      _c(
                        "span",
                        {
                          staticClass: "handle-preview",
                          on: {
                            click: function ($event) {
                              return _vm.onHandlePictureCardPreview(file)
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon el-icon-zoom-in" })]
                      ),
                      !_vm.disabled
                        ? _c(
                            "span",
                            {
                              staticClass: "handle-delete",
                              on: {
                                click: function ($event) {
                                  return _vm.onHandleRemoveFile(file)
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon el-icon-delete" })]
                          )
                        : _vm._e(),
                    ]),
                  ])
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
      _c("c-preview-file", {
        attrs: {
          fileList: _vm.fileList,
          fileIndex: _vm.previewIndex,
          fileType: _vm.fileType,
        },
        model: {
          value: _vm.dialogVisible,
          callback: function ($$v) {
            _vm.dialogVisible = $$v
          },
          expression: "dialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }